<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>诗词</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/listAuthor' }">作者</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-loading="boxLoading">

                <el-form-item label="作者名称：" label-position="left" prop="name">
                    <el-input v-model="form.name" style="width: 200px;" />
                </el-form-item>

                <el-form-item label="图片：" label-position="left" prop="pic">
                    <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>

                <el-form-item label="作者介绍：" label-position="left" prop="detail">
                    <el-input type="textarea" style="width: 300px;" placeholder="详细介绍" v-model="form.detail">
                    </el-input>
                </el-form-item>

                <el-form-item label="排序：" label-position="left" prop="sort">
                    <el-input-number size="mini" v-model="form.sort" label="排序"></el-input-number>
                </el-form-item>


                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectImages from "../../../components/selectImages/index";
export default {
    name: "template-add",
    components: { SelectImages },
    data() {
        return {
            boxLoading: false,
            id: null,
            image: '',
            form: {
                name:'',
                detail:'',
                sort:'',
            },
            rules: {
                name: [
                    { required: true, message: '请输入作者名称', trigger: 'blur' },
                ],
                // pic:[
                // { required: true, message: '请选择图片', trigger: 'change' }
                // ],
                detail: [
                    { required: true, message: '请输入作者信息', trigger: 'blur' },
                    { max: 1000, message: '长度在 1000个字符以内', trigger: 'blur' }

                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
            },
            loading: false
        }
    },
    methods: {
        ...mapActions('listAuthor', ['addAuthor', 'getAuthor']),
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.add()
                }
            });
        },
        async add() {
            this.loading = true
            const { data, res_info } = await this.addAuthor(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('编辑成功！')
            this.$router.push('/listAuthor')
        },
        onSelect(image) {
            this.image = imageUrl + image.path
            this.form.pic = image.path
        },
        onDelete() {
            this.image = ''
        },
        async init() {
            this.boxLoading = true
            const { data } = await this.getAuthor(this.id)
            this.form = JSON.parse(JSON.stringify(data))
            this.boxLoading = false
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.init()
    },
}
</script>

<style scoped>
.margin-l-sm {
    margin-left: 1.25rem;
}
</style>
